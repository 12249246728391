<template>
  <FilterParent v-model="showAdminUserFilters">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="6" class="pa-2">
          <Autocomplete
            :loading="listLoading"
            :totalItems="laneCount"
            :items="allLaneList"
            itemText="title"
            itemValue="id"
            @updateList="getLaneList"
            label="Route"
            v-model="filterObject.lane"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
 
import FilterParent from "@/components/General/FilterParent.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";

export default {
  components: {
    FilterParent,
    
    Autocomplete
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allLaneList: [],
      listLoading: false,
      laneCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getLaneList();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
      getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style>
</style>