<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-2"
          color="secondary"
          v-on="on"
          v-bind="attrs"
          @click="viewLegInfo"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-1"
          color="primary"
          v-on="on"
          v-if="
            params &&
            params.data &&
            [
              'Created',
              'Assigned',
              'Enroute',
              'Reached Pickup Location',
              'Reached Drop Location',
            ].indexOf(params.data.status) > -1 &&
            !(
              params.context &&
              params.context.parentComponent &&
              params.context.parentComponent.selectedDaDetails &&
              params.context.parentComponent.selectedDaDetails.is_sub_hire == 'Yes'
            )
          "
          v-bind="attrs"
          @click="editLeg"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="red"
          v-on="on"
          v-if="
            params &&
            params.data &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.selectedDaDetails &&
            params.context.parentComponent.selectedDaDetails.legs_count &&
            params.context.parentComponent.selectedDaDetails.legs_count > 1 &&
            ['Created', 'Assigned'].indexOf(params.data.status) > -1
          "
          v-bind="attrs"
          @click="deleteLeg()"
        >
          mdi-delete
        </v-icon>
      </template>
      <span>Delete</span>
    </v-tooltip>
  </div>
</template>
<script>
export default {
  methods: {
    viewLegInfo() {
      this.params.context.parentComponent.viewLegInfo(this.params.data);
    },
    editLeg() {
      this.params.context.parentComponent.editLeg(this.params.data);
    },
    deleteLeg() {
      this.params.context.parentComponent.deleteLeg(this.params.data.id);
      
    },
  },
};
</script>

<style></style>
