import { render, staticRenderFns } from "./SideNavigation.vue?vue&type=template&id=58227182&scoped=true&"
import script from "./SideNavigation.vue?vue&type=script&lang=js&"
export * from "./SideNavigation.vue?vue&type=script&lang=js&"
import style0 from "./SideNavigation.vue?vue&type=style&index=0&id=58227182&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58227182",
  null
  
)

export default component.exports