<template>
  <FilterParent v-model="showAdminUserFilters">
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            :showAll="true"
            label="Select Transporter(s)"
            :items="transporterList"
            :returnObject="true"
            itemText="company_name"
            itemValue="id"
            hide-details="auto"
            :multiple="true"
            :chips="true"
            chipWidthStyle="max-width: 3.7vw;"
            v-model="filterObject.transporter"
          >
          </MultiSelect>
        </v-col>
        <v-col cols="12" lg="6" class="pa-2">
          <Autocomplete
            :loading="listLoading"
            :totalItems="laneCount"
            :items="allLaneList"
            itemText="title"
            itemValue="id"
            @updateList="getLaneList"
            label="Route"
            v-model="filterObject.lane"
          />
        </v-col>
        <v-col cols="12" lg="6" class="pa-2">
          <MultiSelect
            label="Container Type *"
            :items="allContainersType"
            :multiple="true"
            :returnObject="true"
            itemText="title"
            itemValue="id"
            :rules="[(v) => !!v || 'Container Type is Required']"
            hide-details="auto"
            v-model="filterObject.container_type"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        depressed
        color="secondary"
        @click="$emit('resetFilters'), (showAdminUserFilters = false)"
      >
        Reset
      </v-btn>

      <v-btn
        color="primary"
        class="ml-4"
        depressed
        @click="$emit('applyFilters'), (showAdminUserFilters = false)"
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import FilterParent from "@/components/General/FilterParent.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";

export default {
  components: {
    FilterParent,
    MultiSelect,
    Autocomplete,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      allLaneList: [],
      transporterList: [],
      allContainersType: [],
      listLoading: false,
      laneCount: 0,
    };
  },
  watch: {
    value(val) {
      if (val) {
        this.getLaneList();
        this.getTransporterList();
        this.getContainersType();
      }
    },
  },
  computed: {
    showAdminUserFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    getContainersType() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        limit: "all",
        ...params,
      };
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.transporterList = res.data;
        })
        .catch(() => {
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        limited: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },
  },
};
</script>

<style>
</style>