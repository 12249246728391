import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getAdminDashboardData(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/admin/dashboard/", {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getTransporterDashboardData(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/transporter/dashboard/", {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerDashboardData(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get("api/v1/customer/dashboard/", {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
