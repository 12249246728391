var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FormComponent',{attrs:{"title":"Create Bid","backRoute":_vm.backRoute,"nonFieldErrors":_vm.formErrors.non_field_errors},scopedSlots:_vm._u([{key:"formActions",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","depressed":"","disabled":!_vm.allFormValidation},on:{"click":_vm.submitForm}},[_vm._v(" Submit ")]),_c('v-btn',{staticClass:"ml-2",attrs:{"color":"secondary","depressed":""},on:{"click":_vm.resetForm}},[_vm._v(" Reset ")])]},proxy:true},{key:"formContent",fn:function(){return [_c('v-stepper',{attrs:{"non-linear":"","flat":""}},[_c('v-stepper-items',[_c('v-stepper-content',{staticClass:"pa-0",attrs:{"step":"1"}},[_c('v-form',{ref:"customerContract",model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',{staticClass:"mx-2 my-0"},[_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12"}},[_c('span',{staticClass:"text-h6 font-weight-regular"},[_vm._v(" Bidding Details ")])]),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('MultiSelect',{attrs:{"showAll":true,"label":"Select Transporter(s)","items":_vm.transporterList,"itemText":"company_name","itemValue":"id","hide-details":"auto","rules":[
                    (val) => !!val.length || 'Transporter is required!',
                  ],"multiple":true,"chips":true,"chipWidthStyle":"max-width: 3.7vw;","error-messages":_vm.formErrors.transporter},on:{"change":function($event){delete _vm.formErrors.transporter}},model:{value:(_vm.bidDetails.transporter),callback:function ($$v) {_vm.$set(_vm.bidDetails, "transporter", $$v)},expression:"bidDetails.transporter"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('MultiSelect',{attrs:{"label":"Job Type *","items":_vm.allJobType,"itemText":"display_name","itemValue":"id","rules":[(v) => !!v || 'Job Type is Required'],"hide-details":"auto","error-messages":_vm.formErrors.job_type},on:{"input":function($event){delete _vm.formErrors.job_type}},model:{value:(_vm.bidDetails.job_type),callback:function ($$v) {_vm.$set(_vm.bidDetails, "job_type", $$v)},expression:"bidDetails.job_type"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('DateField',{attrs:{"inputProps":{
                    dense: true,
                    label: 'End Date *',
                    'hide-details': 'auto',
                    rules: [(val) => !!val || 'End Date is required!'],
                    'error-messages': _vm.formErrors.end_date,
                  },"fieldType":"datetime","required":"","min":_vm.today},on:{"input":function($event){delete _vm.formErrors.end_date}},model:{value:(_vm.bidDetails.end_date),callback:function ($$v) {_vm.$set(_vm.bidDetails, "end_date", $$v)},expression:"bidDetails.end_date"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('InputField',{attrs:{"type":"text","label":"Expected Rate","hide-details":"auto","error-messages":_vm.formErrors.expected_rate},on:{"input":function($event){delete _vm.formErrors.expected_rate}},model:{value:(_vm.bidDetails.expected_rate),callback:function ($$v) {_vm.$set(_vm.bidDetails, "expected_rate", $$v)},expression:"bidDetails.expected_rate"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"6"}},[_c('Autocomplete',{attrs:{"loading":_vm.listLoading,"totalItems":_vm.laneCount,"items":_vm.allLaneList,"label":"Route Code *","itemText":"title","itemValue":"id","rules":[(v) => !!v || 'Route is Required'],"hide-details":"auto","error-messages":_vm.formErrors.lane},on:{"updateList":_vm.getLaneList,"input":function($event){delete _vm.formErrors.lane;
                    delete _vm.formErrors.job_type;}},model:{value:(_vm.bidDetails.lane),callback:function ($$v) {_vm.$set(_vm.bidDetails, "lane", $$v)},expression:"bidDetails.lane"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('MultiSelect',{attrs:{"label":"Container Type *","items":_vm.allContainersType,"itemText":"title","itemValue":"id","returnObject":true,"rules":[(v) => !!v || 'Container Type is Required'],"hide-details":"auto"},model:{value:(_vm.bidDetails.container_type),callback:function ($$v) {_vm.$set(_vm.bidDetails, "container_type", $$v)},expression:"bidDetails.container_type"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"3"}},[_c('InputField',{attrs:{"type":"text","label":"Container Count/Truck *","hide-details":"auto","rules":[(v) => !!v || 'Container Count is Required'],"error-messages":_vm.formErrors.container_count},on:{"input":function($event){delete _vm.formErrors.container_count}},model:{value:(_vm.bidDetails.container_count),callback:function ($$v) {_vm.$set(_vm.bidDetails, "container_count", $$v)},expression:"bidDetails.container_count"}})],1),_c('v-col',{staticClass:"Form-Columns-Dense",attrs:{"cols":"12","lg":"6"}},[_c('InputField',{attrs:{"type":"text","label":"Admin Remarks","hide-details":"auto","error-messages":_vm.formErrors.admin_remarks},on:{"input":function($event){delete _vm.formErrors.admin_remarks}},model:{value:(_vm.bidDetails.admin_remarks),callback:function ($$v) {_vm.$set(_vm.bidDetails, "admin_remarks", $$v)},expression:"bidDetails.admin_remarks"}})],1)],1)],1)],1)],1)],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }