import { render, staticRenderFns } from "./QuotationEdit.vue?vue&type=template&id=3b201b87&scoped=true&"
import script from "./QuotationEdit.vue?vue&type=script&lang=js&"
export * from "./QuotationEdit.vue?vue&type=script&lang=js&"
import style0 from "./QuotationEdit.vue?vue&type=style&index=0&id=3b201b87&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b201b87",
  null
  
)

export default component.exports