<template>
  <div>
    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-1 mr-2"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="viewItem"
        >
          mdi-eye
        </v-icon>
      </template>
      <span>View</span>
    </v-tooltip>

    <v-tooltip top>
      <template #activator="{ on, attrs }">
        <v-icon
          v-if="
            params &&
            params.context &&
            params.context.parentComponent &&
            params.context.parentComponent.permissions &&
            params.context.parentComponent.permissions.change &&
            params.data &&
            ['Created', 'Scheduled', 'Active'].indexOf(params.data.status) > -1
          "
          class="mr-1 ml-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="editItem"
        >
          mdi-pencil
        </v-icon>
      </template>
      <span>Edit</span>
    </v-tooltip>

    <v-tooltip top class="custom-tooltip">
      <template #activator="{ on, attrs }">
        <v-icon
          class="ml-2 mr-1"
          color="primary"
          v-on="on"
          v-bind="attrs"
          @click="cancelDeliveryAdvice"
          :disabled="!shouldShowCancelIcon"
        >
          mdi-close-circle-outline
        </v-icon>
      </template>

      <span>{{ cancelText }}</span>
    </v-tooltip>
    <v-tooltip top class="custom-tooltip">
      <template #activator="{ on, attrs }">
        <v-icon class="ml-2 mr-1" color="primary" v-on="on" v-bind="attrs">
          mdi-information
        </v-icon>
      </template>

      <span>{{ cancelText }} </span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  computed: {
    cancelText() {
      const { params, data, context } = this;
      if (!this.shouldShowCancelIcon && params.data.sub_hire_bid) {
        return "Bid is attached with sub hire";
      } else if (!this.shouldShowCancelIcon) {
        return "Admin can cancel the DA";
      } else {
        return "Cancel";
      }
    },
    shouldShowCancelIcon() {
      const { params, data, context } = this;

      const hasChangePermission =
        params.context &&
        params.context.parentComponent &&
        params.context.parentComponent.permissions &&
        params.context.parentComponent.permissions.change;

      if (
        params &&
        params.data &&
        !params.data.sub_hire_bid &&
        ["Created", "Scheduled", "Active"].includes(params.data.status) &&
        hasChangePermission
      ) {
        return true;
      }

      if (
        params &&
        params.data &&
        !params.data.sub_hire_bid &&
        ["Completed"].includes(params.data.status) &&
        hasChangePermission &&
        params.context.parentComponent.currentProfile.is_primary_user
      ) {
        return true;
      }

      return false;
    },
  },
  methods: {
    viewItem() {
      this.params.context.parentComponent.viewDeliveryAdvicesDetails(
        this.params.data.id
      );
    },
    editItem() {
      this.params.context.parentComponent.editItem(this.params.data);
    },
    cancelDeliveryAdvice() {
      this.params.context.parentComponent.openCancelDaRemarkForm(
        this.params.data.id
      );
    },
  },
};
</script>
<style >
.custom-tooltip.v-tooltip .v-icon[disabled]::after {
  pointer-events: auto !important;
  cursor: pointer !important;
}
</style>
