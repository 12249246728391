<template>
  <FilterParent
    class="primary"
    module="Integration"
    maxWidth="850"
    v-model="showIntegrationLogFilters"
  >
    <template #filterContent>
      <v-row class="ma-0">
        <v-col cols="6" class="Form-Columns">
          <InputField
            hide-details="auto"
            type="date"
            label="Select Log Date"
            v-model="filterObject.created"
          />
        </v-col>

        <v-col cols="5" class="Form-Columns">
          <v-switch
            :label="`Is Success : ${
              filterObject.is_success == true ? 'Yes' : 'No'
            }`"
            class="mt-1"
            hide-details="auto"
            v-model="filterObject.is_success"
          />
        </v-col>
      </v-row>
    </template>

    <template #filterAction>
      <v-btn
        color="secondary"
        depressed
        @click="
          $emit('resetFilters');
          showIntegrationLogFilters = false;
        "
      >
        Reset
      </v-btn>
      <v-btn
        color="primary"
        class="ml-2"
        depressed
        @click="
          $emit('applyFilters');
          showIntegrationLogFilters = false;
        "
      >
        Apply
      </v-btn>
    </template>
  </FilterParent>
</template>

<script>
import FilterParent from "@/components/General/FilterParent.vue";
import InputField from "@/components/FormBaseComponents/InputField.vue";

export default {
  components: {
    FilterParent,
    InputField,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    bindingObject: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      orderStatusList: [],
      orderTypeList: [],
    };
  },

  computed: {
    showIntegrationLogFilters: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filterObject: {
      get() {
        return this.bindingObject;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    currentProfile() {
      let obj = localStorage.getItem("userProfile");
      if (obj) {
        return JSON.parse(obj);
      }
      return null;
    },
  },
  methods: {},
};
</script>
