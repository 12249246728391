<template>
  <ListComponent title="Charges" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchCharge"
          ></v-text-field>
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allChargeList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <ChargeForm
        v-if="permissions && (permissions.add || permissions.change)"
        v-model="showChargeForm"
        :editMode="isEditing"
        :editChargeID="editChargeID"
        @refreshList="itemsPerPageChanged(20)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        color="primary"
        depressed
        @click="addCharge"
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Charge</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import AdminChargeButton from "@/components/AgGridComponents/AdminChargeButton.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import ChargeForm from "@/components/ModuleBased/dialogForms/ChargeForm.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    Pagination,
    AdminChargeButton,
    agColumnHeader: CustomHeader,
    ChargeForm,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Charge Title",
          field: "charge_title",
          sortable: true,
          minWidth: 300,
        },
        {
          headerName: "Description",
          field: "description",
          tooltipField: "description",
          minWidth: 300,
        },
        {
          headerName: "Revenue GL No.",
          field: "gl_no",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Cost GL No.",
          field: "cost_gl_no",
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: `Tax (%)`,
          field: "tax_percentage",
          minWidth: 80,
        },
        {
          headerName: `Rate (${this.$globals.currency})`,
          field: "rate",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Active",
          field: "is_active",
          tooltipField: "is_active",
          minWidth: 80,
        },

        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "AdminChargeButton",
          pinned: "right",
          width: 90,
        },
      ],
      allChargeList: [],
      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 20,
      totalItems: 0,
      filters: {},

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.gridOptions.api.sizeColumnsToFit();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },

      //Charge form
      showChargeForm: false,
      isEditing: false,
      editChargeID: null,
    };
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    searchCharge() {
      this.pageNo = 1;
      this.getChargeList();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getChargeList();
    },
    prevPage() {
      this.pageNo--;
      this.getChargeList();
    },
    nextPage() {
      this.pageNo++;
      this.getChargeList();
    },
    getChargeList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.charge
        .getChargeList(params)
        .then((res) => {
          this.allChargeList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addCharge() {
      this.editChargeID = null;
      this.isEditing = false;
      this.showChargeForm = true;
    },
    editCharge(id) {
      this.editChargeID = id;
      this.isEditing = true;
      this.showChargeForm = true;
    },
    deactivateCharge(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.charge
        .partiallyUpdateCharge(params)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("configuration");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getChargeList();
      }
    }
  },
};
</script>
