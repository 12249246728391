<template>
  <v-app>
    <!-- Top Navigation -->
    <AppBar
      :companyNotifications="companyNotifications"
      :unreadCount="unreadCount"

      @toggleDrawer="drawer = !drawer"
      @showRightDrawer="rightDrawer = true"
       @getOldNotifications="getOldNotifications"
       @refreshNotificationHistory="getNotificationHistory"
    />

    <!-- Side Navigation -->
    <SideNavigation v-model="drawer" />
    <!-- Side Navigation -->
    <RightDrawer v-model="rightDrawer" />

    <!-- Profile -->
    <!-- <ProfileDetails v-model="profileDetails" /> -->

    <!-- Base Content -->
    <v-main>
      <v-container fluid class="pa-4">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import AppBar from "@/components/BaseComponents/AppBar.vue";
import AppFooter from "@/components/BaseComponents/AppFooter.vue";
import RightDrawer from "@/components/BaseComponents/RightDrawer.vue";
import SideNavigation from "@/components/BaseComponents/SideNavigation.vue";
import LoaderScreen from "@/components/General/LoaderScreen.vue";

export default {
  components: {
    AppBar,
    SideNavigation,
    AppFooter,
    RightDrawer,
    LoaderScreen,
  },

  name: "Transporter",

  data() {
    return {
       userCompanyId: null,
      userType: null,
      unreadCount: 0,
      companyNotifications: [],
      // Drawer vars
      drawer: false,
      rightDrawer: false,

      text: null,
    };
  },
  computed: {
    currentProfile() {
      let obj = JSON.parse(localStorage.getItem("userProfile"));
      if (!obj) {
        return {};
      } else {
        return obj;
      }
    },
  },
  methods: {
    setupWS(userType) {
      if (!this.$ws.instance) {
        this.$ws.createInstance(userType);
        this.$ws.instance.onopen = this.onOpen;
      } else {
        this.onOpen();
      }
      this.$ws.instance.onmessage = this.onMessage;
    },
    getOldNotifications(refreshHistory) {
      if (refreshHistory) {
        this.getNotificationHistory();
      }
    },
    onOpen() {
      this.getNotificationHistory();
    },
    getNotificationHistory(params = {}) {
      params = {
        company: this.currentProfile.company,
        limit: this.limit,
        offset: this.offset,
        ...params,
      };
      if (this.companyNotifications.length && !params.clearAll) {
        params.offset = this.companyNotifications.length;
      }
      this.$api.company
        .getCompanyNotifications(params)
        .then((res) => {
          console.log(res,'res');
          if (res.additional_info) {
            this.unreadCount = res.additional_info.unread_count;
          }
          if (this.companyNotifications.length && !params.clearAll) {
            this.companyNotifications.push(...res.data);
          } else {
            this.companyNotifications = res.data;
          }
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    showLoader(value) {
      this.showOverlay = value;
    },
    showToastMessage(options) {
      this.snackbar = true;
      this.text = options.message;
    },
  },
  beforeCreate() {
    this.$bus.$emit("showLoader", true);

    this.$api.authentication
      .getProfileInfo()
      .then((res) => {
        this.$bus.$emit("showLoader", false);
        localStorage.setItem("user_type", res.data.user_type);
        localStorage.setItem("userProfile", JSON.stringify(res.data));
        const preference = res?.data?.user_preferences || {};
        localStorage.setItem("userPreferences", JSON.stringify(preference));
        localStorage.setItem(
          "permissions",
          JSON.stringify(res.data.permissions)
        );
        if (res.data) {
          this.userType = "transporter";
          if (this.userType) {
            this.setupWS(this.userType);
          }
        }
      })
      .catch((err) => {
        this.$bus.$emit("showLoader", false);
      });
  },
  created() {
    this.$bus.$on("socketMessageReceived", (data) => {
      let socketDataType = null;
      if (data.type && data.type == "notification_received") {
        let newNotification = { ...data.data };
        this.unreadCount = data.data.unread_count;
        this.companyNotifications.unshift(newNotification);
      }
      if (
        this.$route &&
        this.$route.path &&
        !this.$route.path.includes("operations")
      ) {
        if (this.userType == "customer") {
          socketDataType = "message_from_transporter";
        } else if (this.userType == "transporter") {
          socketDataType = "message_from_customer";
        }
        if (
          socketDataType &&
          data.type &&
          [socketDataType].indexOf(data.type) > -1
        ) {
          this.$bus.$emit("showToastMessage", {
            message: `New message in Trip: ${data.data.trip_id} from "${data.data.company_name}"`,
            color: "blue",
            hasActions: true,
          });
        }
      }
    });
  },
  async mounted() {
    // Global Event Listener
    this.$bus.$on("showToastMessage", this.showToastMessage);
    this.$bus.$on("showLoader", this.showLoader);

    // Google map loading code
    if (window.google == undefined) {
      const loader = new Loader({
        apiKey: process.env.mapKey,
        version: "weekly",
        libraries: ["places", "geometry", "drawing"],
      });
      await loader.load();
    }
  },
  beforeDestroy() {
    this.$bus.$off("showToastMessage", this.showToastMessage);
    this.$bus.$off("showLoader", this.showLoader);
  },
};
</script>