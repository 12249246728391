import { customerInvoice } from "@/utils/constants";
import { handleError, handleResponse } from "@/utils/functions";

export default (axios) => ({
  getCustomerInvoiceList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(customerInvoice.customerInvoiceBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCustomerInvoice(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(customerInvoice.customerInvoiceBase, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addCustomerInvoiceBulkCreate(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerInvoice.customerInvoiceBase}bulk_create_invoice/`, data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  downloadDAExcel(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/download_da_excel_sheet`, {
          responseType: 'blob',
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addDAToInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${customerInvoice.customerInvoiceBase}${payload.id}/add_invoice_da/`, payload.data)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  similarCustomerInvoiceDA(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/similar_customer_invoice_da`,)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  sendPDFEmail(payload) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${payload.id}/send_pdf_email`, {
          params: payload.emailId
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceBlopObject(id, params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/download`, {
          params: params,
          responseType: "blob"
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceDetails(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/view/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  createCustomerInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${customerInvoice.customerInvoiceBase}`,
          payload
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateCustomerInvoice(payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${customerInvoice.customerInvoiceBase}${payload.id}/`,
          payload.data
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  partiallyUpdateCustomerInvoice(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(
          `${customerInvoice.customerInvoiceBase}${id}/`,
          payload
        )
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceOptions() {
    return new Promise((resolve, reject) => {
      axios
        .options(customerInvoice.customerInvoiceBase)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceLogs(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/invoice_logs/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceGroup(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.customerInvoiceBase}${id}/group_by_invoice/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getCustomerInvoiceAdditionalChargeList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.singleChargeBase}`, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getChargeObject(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${customerInvoice.singleChargeBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  addSingleCharge(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(customerInvoice.singleChargeBase, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  updateSingleCharge(id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${customerInvoice.singleChargeBase}${id}/`, payload)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  deleteSingleCharge(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${customerInvoice.singleChargeBase}${id}/`)
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
  getBulkIvoiceLogsList(params = {}) {
    return new Promise((resolve, reject) => {
      axios
        .get(customerInvoice.bulkInvoiceLogsBase, {
          params: params,
        })
        .then((res) => {
          resolve(handleResponse(res));
        })
        .catch((err) => {
          reject(handleError(err));
        });
    });
  },
});
