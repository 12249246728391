<template>
  <v-dialog fullscreen v-model="showBulkOperationsDialog">
    <v-card>
      <v-card-title class="secondary white--text rounded-0">
        <span> Bulk Operations </span>
        <v-spacer></v-spacer>
        <v-btn
          icon
          @click="
            resetbulkLegUpdateDialog(), (showBulkOperationsDialog = false)
          "
        >
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="px-3 pb-3 pt-2">
        <v-btn
          depressed
          medium
          color="primary"
          class="mr-3"
          @click="updateBonusData"
        >
          Update Bonus
        </v-btn>
        <v-form v-if="showBulkOperationsDialog" v-model="isValid">
          <v-row class="mt-0 pa-1">
            <v-col cols="12" md="6" lg="3" class="pa-1" v-if="showstartDate">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'Start Date *',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                v-model="filterDetails.start_date"
              />
            </v-col>
            <v-col cols="12" md="6" lg="3" class="pa-1" v-if="showendDate">
              <DateField
                :inputProps="{
                  dense: true,
                  label: 'End Date *',
                  clearable: true,
                  'hide-details': 'auto',
                }"
                :min="filterDetails.start_date"
                :max="lastEndDate ? lastEndDate : null"
                v-model="filterDetails.end_date"
              />
            </v-col>
            <v-col cols="2" md="6" lg="3" class="pa-1" v-if="showendDate">
              <MultiSelect
                label="Branch"
                :items="allBranch"
                itemText="branch_code"
                itemValue="id"
                hide-details="auto"
                :clearable="true"
                v-model="filterDetails.branch"
              />
            </v-col>
            <v-col cols="2" md="6" lg="3" class="pa-1" v-if="showendDate">
              <MultiSelect
                label="Depot"
                :items="allDepot"
                itemText="depot_name"
                itemValue="id"
                hide-details="auto"
                :clearable="true"
                v-model="filterDetails.depot"
              />
            </v-col>
            <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
              <MultiSelect
                hide-details="auto"
                label="Source Location "
                :items="sourceList"
                itemText="title"
                itemValue="id"
                :item-disabled="
                  (item) => {
                    return item.id == filterDetails.mid_location;
                  }
                "
                :returnObject="false"
                v-model="filterDetails.source_location"
                :error-messages="formErrors.source_location"
                @change="delete formErrors.source_location"
              />
            </v-col>
            <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
              <MultiSelect
                hide-details="auto"
                label="Mid Location "
                :items="midList"
                itemText="title"
                itemValue="id"
                :returnObject="false"
                :item-disabled="
                  (item) => {
                    return (
                      item.id == filterDetails.source_location ||
                      item.id == filterDetails.destination_location
                    );
                  }
                "
                v-model="filterDetails.mid_location"
                :error-messages="formErrors.mid_location"
                @change="delete formErrors.mid_location"
              />
            </v-col>
            <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
              <MultiSelect
                hide-details="auto"
                label="Destination Location"
                :items="destinationList"
                itemText="title"
                itemValue="id"
                :returnObject="false"
                :item-disabled="
                  (item) => {
                    return item == filterDetails.mid_location;
                  }
                "
                :clearable="true"
                v-model="filterDetails.destination_location"
                :error-messages="formErrors.destination_location"
                @change="delete formErrors.destination_location"
              />
            </v-col>
            <v-col cols="4" lg="3" class="pa-1" v-if="showRoute">
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                @click="searchTrips"
              >
                Search
              </v-btn>
            </v-col>
            <v-col cols="2" lg="3" class="pa-1" v-if="showendDate">
              <v-text-field
                label="Search On Enter"
                outlined
                dense
                append-icon="mdi-magnify"
                clearable
                @click:clear="onEnter()"
                hide-details="auto"
                v-model="filterDetails.search"
                v-on:keyup.enter="onEnter"
              ></v-text-field>
            </v-col>

            <v-col cols="4" lg="3" class="Form-Columns-Dense" v-if="showRoute">
              <InputField
                hide-details="auto"
                type="number"
                label="Update Bonus *"
                :rules="[
                  (v) => !!v || 'Bonus is Required',
                  (v) => v > 0 || 'Bonus can not be less than 0',
                ]"
                :clearable="true"
                v-model="filterDetails.bonus"
              />
            </v-col>
            <v-col cols="4" class="pa-1" v-if="showRoute">
              <v-btn
                depressed
                color="primary"
                class="mr-2"
                :disabled="
                  !(selectedRows && selectedRows.length && filterDetails.bonus)
                "
                @click="submitForm"
              >
                Submit
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-text class="pt-0 px-0 pb-2">
        <v-divider></v-divider>
        <v-divider></v-divider>
      </v-card-text>
      <span class="text-h6 font-weight-regular ml-4">
        Total Selected Leg -
        <span class="font-weight-bold"> {{ legCount }} </span>
      </span>
      <v-card-text class="px-4 pb-3">
        <v-row>
          <v-col cols="12" class="pt-3 pb-1">
            <AgGridVue
              style="width: 100%; height: calc(100vh - 270px)"
              class="small-table ag-theme-alpine"
              id="advancedSearchGrid"
              @selection-changed="onSelectionChanged"
              :columnDefs="headers"
              :context="context"
              rowSelection="multiple"
              @grid-ready="onGridReady"
              :defaultColDef="defaultColDef"
              :grid-options="gridOptions"
              :suppressDragLeaveHidesColumns="true"
              :rowData="allLegList"
            ></AgGridVue>
          </v-col>

          <v-col v-if="totalLegs > 0" cols="12" class="d-flex justify-end py-0">
            <Pagination
              :pageNo="pageNo"
              :totalItems="totalLegs"
              :pageSize="itemsPerPage"
              @itemsPerPageChange="itemsPerPageChanged"
              @prevPage="prevPage"
              @nextPage="nextPage"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import { formatDataKeys } from "@/utils/functions";
import { utils, write } from "xlsx";

export default {
  components: {
    DateField,
    AgGridVue,
    InputField,
    Autocomplete,
    Pagination,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val) {
        this.getLocationList();
        this.getBranchList();
        this.getDepotList();
      } else {
        this.resetbulkLegUpdateDialog();
      }
    },
  },
  computed: {
    showBulkOperationsDialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  data() {
    return {
      legdataList: [],
      selectedRows: [],
      source_data: null,
      sorting: {},
      formErrors: {},
      isValid: true,
      lastEndDate: null,
      legCount: 0,
      filterDetails: {},
      sourceList: [],
      midList: [],
      destinationList: [],
      allBranch: [],
      allDepot: [],
      allLegList: [],
      showstartDate: false,
      showendDate: false,
      showRoute: false,
      filters: {},
      // pagination vars
      pageNo: 1,
      itemsPerPage: 100,
      totalLegs: 100,
      containerCentric: "No",
      tab: null,
      legListTimeout: null,

      // ag-grid vars
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        rowStyle: {
          "font-size": "10px",
        },
        rowHeight: 35,
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
      headers: [
        {
          headerName: "Customer",
          field: "customer_name",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "DA",
          field: "da_no",
          sortable: true,
          minWidth: 65,
        },

        {
          headerName: "Leg",
          field: "leg_id",
          minWidth: 75,
          tooltipField: "leg_id",
          sortable: true,
        },

        {
          headerName: "Movement Date",
          field: "movement_date",
          sortable: true,
          minWidth: 70,
          valueFormatter: (params) => {
            return this.$globals.formatDate(params.value);
          },
          tooltipValueGetter: (params) => {
            return this.$globals.formatDate(params.value);
          },
        },

        {
          headerName: "Source",
          field: "source_location_code",
          sortable: true,
          minWidth: 80,
          tooltipField: "source_address",
        },
        {
          headerName: "Mid",
          field: "mid_location_code",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Destination",
          field: "destination_location_code",
          sortable: true,
          minWidth: 80,
        },

        {
          headerName: "Bonus",
          field: "bonus",
          minWidth: 75,
          tooltipField: "Bonus",
          sortable: true,
        },

        {
          headerName: "Branch",
          field: "branch",
          sortable: true,
          minWidth: 50,
          tooltipField: "branch",
        },
        {
          headerName: "Depot",
          field: "depot",
          sortable: true,
          minWidth: 50,
          tooltipField: "depot",
        },
        {
          headerName: "Select for Update Bonus",
          minWidth: 50,
          checkboxSelection: true,
          headerCheckboxSelection: true,
        },
      ],
    };
  },
  methods: {
    onEnter() {
      this.pageNo = 1;
      this.getLegsList();
    },
    onSelectionChanged() {
      this.selectedRows = this.gridApi.getSelectedRows();
      this.legCount = this.selectedRows.length;
    },

    checkDates() {
      if (this.filters.start_date) {
        let currentDate = new Date(this.filters.start_date);
        let endDate = currentDate;
        endDate.setDate(currentDate.getDate() + 5);
        this.lastEndDate = endDate.toISOString().slice(0, 10);
      } else {
        delete this.filters.end_date;
      }
    },
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        id: this.selectedRows.map((da) => da.id),
        bonus: this.filterDetails.bonus,
      };
      this.$api.trip
        .bulkUpdateLegbonus(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Leg Bonus Updated Successfully!",
            color: "success",
          });
          this.formErrors = {};
          this.resetbulkLegUpdateDialog();
          this.legCount = 0;
          this.$emit("refreshList");
        })
        .catch((err) => {
          this.formErrors = {};
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Bonus!",
            color: "error",
          });
        });
    },
    getBranchList(params = {}) {
      params = {
        limit: "all",
        ...params,
      };
      this.$api.branch
        .getBranchList(params)
        .then((res) => {
          this.allBranch = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getDepotList(params = {}) {
      params = {
        limit: "all",
      };
      this.$api.depot
        .getDepotList(params)
        .then((res) => {
          this.allDepot = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getLegsList(params = {}) {
      if (this.legListTimeout) {
        clearTimeout(this.legListTimeout);
      }
      this.legListTimeout = setTimeout(() => {
        this.$bus.$emit("showLoader", true);
        params = {
          offset: this.offset,
          limit: this.itemsPerPage,
          ...this.filterDetails,
          ...params,
          is_sub_hire: false,
        };
        if ("ordering" in params && params.ordering.length > 0) {
          params = { ...params, ordering: params.ordering.join(",") };
        }

        let function_name = "getLegList";

        if (this.containerCentric === "Yes") {
          function_name = "getLegContainerList";
        }

        this.$api.trip[function_name](params)
          .then((res) => {
            this.allLegList = res.data;
            this.totalLegs = res.count;
            this.$bus.$emit("showLoader", false);
          })
          .catch((err) => {
            console.error(err);
            this.$bus.$emit("showLoader", false);
          });
      }, 100);
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchTrips() {
      //   this.pageNo = 1;
      this.getLegsList();
      this.legCount = 0;
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLegsList();
      this.legCount = 0;
    },
    prevPage() {
      this.pageNo--;
      this.getLegsList();
      this.legCount = 0;
    },
    nextPage() {
      this.pageNo++;
      this.getLegsList();
      this.legCount = 0;
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.itemsPerPageChanged(50);
    },
    getLocationList() {
      this.$bus.$emit("showLoader", true);

      this.$api.location
        .getLocationSelectList({ limit: "all" })
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.sourceList = res.data;
          this.midList = res.data;
          this.destinationList = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    updateBonusData() {
      this.showstartDate = true;
      this.showendDate = true;
      this.showRoute = true;
    },

    resetbulkLegUpdateDialog() {
      this.filters = {};
      this.allLegList = null;
      this.selectedRows = null;
      this.showstartDate = false;
      this.showendDate = false;
      this.showRoute = false;
      this.showBulkOperationsDialog = false;
      this.filterDetails = {};
    },
  },
};
</script>
