import { handleResponse, handleError } from '@/utils/functions'

export default (axios) => ({
  login(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/login/', data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
  sendOtp(data) {

    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/reset_password_request/', data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
  restPassword(data) {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/reset_password/', data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
  getUsername(data) {
    console.log(data);
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/request_username/', data)
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(handleError(err))
        })
    })
  },
  logout() {
    return new Promise((resolve, reject) => {
      axios
        .post('api/v1/logout/')
        .then((res) => {
          resolve(res)
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
  getProfileInfo() {
    return new Promise((resolve, reject) => {
      axios
        .get('api/v1/profile/')
        .then((res) => {
          resolve(handleResponse(res))
        })
        .catch((err) => {
          reject(err)
        })
    })
  },
})
