import { render, staticRenderFns } from "./OrderEdit.vue?vue&type=template&id=46c26a9f&scoped=true&"
import script from "./OrderEdit.vue?vue&type=script&lang=js&"
export * from "./OrderEdit.vue?vue&type=script&lang=js&"
import style0 from "./OrderEdit.vue?vue&type=style&index=0&id=46c26a9f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46c26a9f",
  null
  
)

export default component.exports