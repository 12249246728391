<template>
  <v-dialog width="1000" v-model="showTermsConditionForm">
    <v-card>
      <v-card-title class="secondary white--text">
        <span> {{ editMode ? "Update" : "Add" }} Terms & Condition </span>

        <v-spacer></v-spacer>

        <v-btn icon @click="resetForm(), (showTermsConditionForm = false)">
          <v-icon class="white--text">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-2">
        <v-form
          ref="termsConditionForm"
          v-if="showTermsConditionForm"
          v-model="isValid"
        >
          <v-row class="my-0">
            <v-col cols="12" lg="6" class="Form-Columns">
              <InputField
                type="text"
                label="Name *"
                required
                :rules="[(val) => !!val || 'Name is Required!']"
                hide-details="auto"
                v-model="termsConditionDetails.name"
                :error-messages="formErrors.name"
                @input="delete formErrors.name"
              />
            </v-col>

            <v-col cols="12" lg="6" class="Form-Columns">
              <MultiSelect
                label="Module Name *"
                :items="allModuleName"
                :rules="[(val) => !!val || 'Module Name is Required!']"
                hide-details="auto"
                v-model="termsConditionDetails.module_name"
                :error-messages="formErrors.module_name"
                @change="delete formErrors.module_name"
              />
            </v-col>

            <v-col cols="12" lg="10" class="Form-Columns">
              <v-file-input
                label="Document *"
                name="document"
                accept="image/x-png,image/jpg,image/jpeg,application/pdf"
                dense
                outlined
                prepend-icon=""
                prepend-inner-icon="mdi-attachment"
                :rules="fileRules"
                hide-details="auto"
                :required="isRequired"
                v-model="document"
                :error-messages="formErrors.document"
                @change="delete formErrors.document, getFileData($event)"
              />
            </v-col>

            <v-col cols="12" lg="2" class="Form-Columns">
              <v-switch
                hide-details="auto"
                v-model="termsConditionDetails.is_default"
                :label="`Default : ${
                  termsConditionDetails.is_default == 'Yes' ? 'Yes' : 'No'
                }`"
                true-value="Yes"
                false-value="No"
                class="mt-1"
                :error-messages="formErrors.is_default"
                @change="delete formErrors.is_default"
              />
            </v-col>

            <v-col
              cols="12"
              lg="5"
              class="py-1"
              v-if="editMode && termsConditionDetails.document_url"
            >
              <span>Preview Document </span>
              <a
                :href="termsConditionDetails.document_url"
                target="_black"
                name="uploaded-document-type"
              >
                <v-icon color="primary">mdi-eye</v-icon>
              </a>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="justify-end">
        <v-btn
          color="primary"
          depressed
          :disabled="!isValid"
          @click="submitForm"
        >
          Submit
        </v-btn>

        <v-btn
          class="ml-3"
          color="secondary"
          v-if="!editMode"
          depressed
          @click="resetForm"
        >
          Reset
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import SelectField from "@/components/FormBaseComponents/SelectField.vue";

export default {
  components: {
    InputField,
    SelectField,
    MultiSelect,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editTermsConditionID: {
      type: [Number, null],
      default: null,
    },
  },
  watch: {
    value(val) {
      if (val) {
        if (this.editMode) {
          this.getTermsConditionObject(this.editTermsConditionID);
        }
        this.getTermsConditionOptions();
      } else {
        this.resetForm();
      }
    },
  },
  computed: {
    showTermsConditionForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isRequired() {
      console.log("hello");
      if (this.editMode) {
        return false;
      } else {
        return true;
      }
    },

    fileRules() {
      return [
        (file) => !!file || !this.isRequired || "Please select a file",
        (file) =>
          !file ||
          file.size < 2e6 ||
          !this.isRequired ||
          "File size should be less than 2 MB!",
      ];
    },
  },
  data() {
    return {
      formErrors: {},
      isValid: true,
      isTextInput: false,
      document: null,

      termsConditionDetails: {
        is_default: "No",
      },

      allModuleName: [],
    };
  },
  methods: {
    getFileData(fileInput) {
      if (fileInput) {
        if (this.checkFileFormat(fileInput)) {
          if (fileInput && !this.document) {
            this.$nextTick(() => {
              this.document = null;
            });
            this.$bus.$emit("showToastMessage", {
              message: "Select Document type first!",
              color: "error",
            });
            return false;
          }
        } else {
          alert("This file format is not Supported!");
        }
      }
    },
    checkFileFormat(event) {
      const name = event.name;
      const lastDot = name.lastIndexOf(".");
      const ext = name.substring(lastDot + 1);
      let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
      if (!allowed.exec(ext)) {
        this.$nextTick(() => {
          this.document = null;
        });
        return false;
      } else {
        return true;
      }
    },

    uploadDocument(id = null) {
      return new Promise((resolve, reject) => {
        let payload = {
          id: id,
          data: null,
        };
        let newForm = new FormData();
        newForm.append("document", this.document);
        payload.data = newForm;
        this.$api.termsCondition
          .uploadTermsConditionDocument(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((err) => {
            this.formErrors = err.data;
            if (err && err.data) {
              this.$bus.$emit("showToastMessage", {
                message: "Documents updated successfully!",
                color: "success",
              });
            }
            this.$bus.$emit("showLoader", false);
          });
      });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      if (!this.editMode) {
        this.termsConditionDetails = {
          ...this.termsConditionDetails,
        };

        this.$api.termsCondition
          .addTermsCondition(this.termsConditionDetails)
          .then(async (res) => {
            if (this.document && res?.data?.response_data?.id) {
              await this.uploadDocument(res.data.response_data.id);
            }
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Document added successfully!",
              color: "success",
            });
            this.resetForm();
            this.showTermsConditionForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Couldn't add Document!",
              color: "error",
            });
            this.formErrors = err.data;
          });
      } else {
        let new_data = { ...this.termsConditionDetails };

        delete new_data.document;

        let payload = {
          id: this.termsConditionDetails.id,
          data: new_data,
        };
        this.$api.termsCondition
          .updateTermsCondition(payload)

          .then(async (res) => {
            if (this.document) {
              await this.uploadDocument(res.data.response_data.id);
            }
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: "Document updated successfully!",
              color: "success",
            });
            this.resetForm();
            this.showTermsConditionForm = false;
            this.$emit("refreshList");
          })
          .catch((err) => {
            console.log(err);
            this.$bus.$emit("showLoader", false);
            this.$bus.$emit("showToastMessage", {
              message: err?.data[0],
              color: "error",
            });
            this.formErrors = err.data;
          });
      }
    },

    resetForm() {
      if (this.$refs.termsConditionForm) {
        this.$refs.termsConditionForm.reset();
      }
      this.termsConditionDetails = {
        is_default: "No",
      };
    },

    getTermsConditionObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.termsCondition
        .getTermsConditionObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.termsConditionDetails = res.data;
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },

    getTermsConditionOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.termsCondition
        .getTermsConditionOptions()
        .then((res) => {
          this.allModuleName = res.data.actions.POST.module_name.choices.map(
            (obj) => obj.value
          );
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
  },
};
</script>
