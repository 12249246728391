<template>
  <FormComponent
    title="Edit Customer Tariff"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <template #formActions>
      <v-btn color="primary" depressed @click="submitForm" :disabled="!isValid">
        Update
      </v-btn>
    </template>

    <template #formContent>
      <v-stepper v-model="stepper" non-linear flat>
        <v-stepper-header class="elevation-0">
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? isValid : true)]"
            step="1"
            editable
          >
            Tariff Details
          </v-stepper-step>
          <v-divider class="ma-0"></v-divider>
          <v-stepper-step
            :rules="[() => (Object.keys(formErrors).length ? isValid2 : true)]"
            step="2"
            editable
          >
            Charges
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="pa-0">
            <v-form v-model="isValid" ref="customerContract">
              <v-row class="mx-2 my-0">
                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Tariff Details - {{ contractDetails.contract_id }}
                  </span>
                </v-col>
                <!-- <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Select Customer *"
                    :items="allCustomerList"
                    itemText="company_name"
                    itemValue="id"
                    :rules="[(val) => !!val || 'Customer is Required!']"
                    hide-details="auto"
                    v-model="contractDetails.customer"
                    :error-messages="formErrors.customer"
                    @input="delete formErrors.customer"
                  ></MultiSelect>
                </v-col> -->
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <Autocomplete
                    :loading="listLoading"
                    :totalItems="customerCount"
                    :items="allCustomerList"
                    @updateList="getCustomerList"
                    label="Select Customer *"
                    itemText="company_name"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Customer is Required']"
                    hide-details="auto"
                    v-model="contractDetails.customer"
                    :error-messages="formErrors.customer"
                    @input="delete formErrors.customer"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <MultiSelect
                    label="Job Type *"
                    :items="allJobType"
                    itemText="display_name"
                    itemValue="value"
                    :rules="[(v) => !!v || 'Job Type is Required']"
                    hide-details="auto"
                    v-model="contractDetails.job_type"
                    :error-messages="formErrors.job_type"
                    @input="delete formErrors.job_type"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'Start Date *',
                      'hide-details': 'auto',
                      rules: [(val) => !!val || 'Start Date is required!'],
                      'error-messages': formErrors.start_date,
                    }"
                    required
                    :min="today"
                    v-model="contractDetails.start_date"
                    @input="delete formErrors.start_date"
                  />
                </v-col>
                <v-col cols="12" lg="3" class="Form-Columns-Dense">
                  <DateField
                    :inputProps="{
                      dense: true,
                      label: 'End Date *',
                      'hide-details': 'auto',
                      rules: [(val) => !!val || 'End Date is required!'],
                      'error-messages': formErrors.end_date,
                    }"
                    required
                    :min="contractDetails.start_date"
                    v-model="contractDetails.end_date"
                    @input="delete formErrors.end_date"
                  />
                </v-col>
                <v-col cols="12" class="Form-Columns-Dense">
                  <Autocomplete
                    :loading="listLoading"
                    :totalItems="laneCount"
                    :items="allLaneList"
                    @updateList="getLaneList"
                    label="Route *"
                    itemText="title"
                    itemValue="id"
                    :rules="[(v) => !!v || 'Route is Required']"
                    hide-details="auto"
                    v-model="contractDetails.lane"
                    :error-messages="formErrors.lane"
                    @input="
                      delete formErrors.lane;
                      delete formErrors.job_type;
                    "
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Admin Remarks"
                    hide-details="auto"
                    v-model="contractDetails.admin_remarks"
                    :error-messages="formErrors.admin_remarks"
                    @input="delete formErrors.admin_remarks"
                  />
                </v-col>
                <v-col cols="12" lg="6" class="Form-Columns-Dense">
                  <InputField
                    type="text"
                    label="Customer Remarks"
                    hide-details="auto"
                    v-model="contractDetails.customer_remarks"
                    :error-messages="formErrors.customer_remarks"
                    @input="delete formErrors.customer_remarks"
                  />
                </v-col>

                <v-col cols="12" class="Form-Columns-Dense">
                  <span class="text-h6 font-weight-regular">
                    Container Details
                  </span>
                </v-col>
                <v-col cols="12">
                  <v-row
                    v-for="(con, i) in contractDetails.customer_contract_rates"
                    :key="i"
                  >
                    <v-col cols="12" lg="6" class="Form-Columns-Dense">
                      <MultiSelect
                        label="Container Type *"
                        :items="allContainersType"
                        itemText="title"
                        itemValue="id"
                        :rules="[(v) => !!v || 'Container Type is Required']"
                        hide-details="auto"
                        v-model="con.container_type"
                        :error-messages="
                          formErrors &&
                          formErrors.customer_contract_rates &&
                          formErrors.customer_contract_rates[i] &&
                          formErrors.customer_contract_rates[i].container_type
                            ? formErrors.customer_contract_rates[i]
                                .container_type
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.customer_contract_rates &&
                          formErrors.customer_contract_rates[i] &&
                          formErrors.customer_contract_rates[i].container_type
                            ? delete formErrors.customer_contract_rates[i]
                                .container_type
                            : null
                        "
                      />
                    </v-col>
                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        label="Container Count/Truck *"
                        min="1"
                        max="4"
                        :rules="[
                          (v) => !!v || 'Container Count/Truck is Required',
                          (v) =>
                            v > 0 ||
                            'Container Count/Truck can not be less than 1',
                          (v) =>
                            v < 5 ||
                            'Container Count/Truck can not be more than 4',
                        ]"
                        hide-details="auto"
                        v-model="con.container_count"
                        :error-messages="
                          formErrors &&
                          formErrors.customer_contract_rates &&
                          formErrors.customer_contract_rates[i] &&
                          formErrors.customer_contract_rates[i].container_count
                            ? formErrors.customer_contract_rates[i]
                                .container_count
                            : []
                        "
                        @input="
                          formErrors &&
                          formErrors.customer_contract_rates &&
                          formErrors.customer_contract_rates[i] &&
                          formErrors.customer_contract_rates[i].container_count
                            ? delete formErrors.customer_contract_rates[i]
                                .container_count
                            : null
                        "
                      />
                    </v-col>

                    <v-col cols="12" lg="2" class="Form-Columns-Dense">
                      <InputField
                        type="number"
                        min="0"
                        :label="`Rate (${$globals.currency}) *`"
                        required
                        :rules="[
                          (v) => !!v || 'Rate is Required',
                          (value) =>
                            value > 0 ||
                            'Rate can not be less than or equal to 0',
                        ]"
                        hide-details="auto"
                        v-model="con.rate"
                        :error-messages="
                          formErrors.customer_contract_rates[i].rate
                        "
                        @input="
                          delete formErrors.customer_contract_rates[i].rate
                        "
                      />
                    </v-col>

                    <v-col
                      cols="12"
                      lg="2"
                      class="Form-Columns-Dense align-self-center"
                    >
                      <v-btn
                        color="primary"
                        small
                        depressed
                        @click="addContainerType"
                        class="mr-2"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>

                      <v-btn
                        color="secondary"
                        small
                        depressed
                        :disabled="
                          contractDetails.customer_contract_rates.length == 1
                        "
                        @click="removeContainerType(i)"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="Form-Columns-Dense mt-6">
                  <v-btn
                    color="primary"
                    @click="stepper = 2"
                    :disabled="!isValid"
                  >
                    Next
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>

          <v-stepper-content step="2" class="pa-0">
            <v-row
              class="mx-2 my-0"
              v-if="contractDetails.customer_contract_charges"
            >
              <v-col cols="6" class="Form-Columns-Dense">
                <span class="text-h6 font-weight-regular"> Add Charge </span>
              </v-col>
              <v-col cols="6" class="Form-Columns-Dense text-right">
                <v-btn
                  depressed
                  small
                  color="primary"
                  @click="contractDetails.customer_contract_charges.push({})"
                  :disabled="
                    contractDetails.customer_contract_charges.length >= 5
                  "
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>

              <v-col cols="12" class="Form-Columns-Dense">
                <v-divider></v-divider>
              </v-col>

              <v-col cols="12">
                <v-row
                  v-for="(
                    charge, i
                  ) in contractDetails.customer_contract_charges"
                  :key="i + keyUpdate"
                  class="ma-0"
                >
                  <v-col cols="auto" class="Form-Columns">
                    <span class="text-h6"> # {{ i + 1 }} </span>
                  </v-col>

                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <MultiSelect
                      label="Charge *"
                      :items="allCharges"
                      itemText="charge_title"
                      itemValue="id"
                      :rules="[(v) => !!v || 'Charge is Required']"
                      :returnObject="true"
                      hide-details="auto"
                      v-model="charge.charge"
                      :error-messages="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i] &&
                        formErrors.customer_contract_charges[i].charge
                          ? formErrors.customer_contract_charges[i].charge
                          : null
                      "
                      @input="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i]
                          ? delete formErrors.customer_contract_charges[i]
                              .charge
                          : null;
                        setChargeDetails($event, i);
                      "
                    />
                  </v-col>
                  <v-col cols="12" lg="3" class="Form-Columns-Dense">
                    <InputField
                      label="Description"
                      readonly
                      :clearable="false"
                      hide-details="auto"
                      v-model="charge.description"
                      :error-messages="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i] &&
                        formErrors.customer_contract_charges[i].description
                          ? formErrors.customer_contract_charges[i].description
                          : null
                      "
                      @input="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i]
                          ? delete formErrors.customer_contract_charges[i]
                              .description
                          : null
                      "
                    />
                  </v-col>

                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <InputField
                      label="Revenue GL No."
                      readonly
                      :clearable="false"
                      hide-details="auto"
                      v-model="charge.gl_no"
                      :error-messages="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i] &&
                        formErrors.customer_contract_charges[i].gl_no
                          ? formErrors.customer_contract_charges[i].gl_no
                          : null
                      "
                      @input="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i]
                          ? delete formErrors.customer_contract_charges[i].gl_no
                          : null
                      "
                    />
                  </v-col>
                  <v-col cols="12" lg="1" class="Form-Columns-Dense">
                    <InputField
                      type="number"
                      label="TAX (%)"
                      readonly
                      :clearable="false"
                      hide-details="auto"
                      v-model="charge.tax_percentage"
                    />
                  </v-col>
                  <v-col cols="12" lg="2" class="Form-Columns-Dense">
                    <InputField
                      type="number"
                      :label="`Rate (${$globals.currency}) *`"
                      :disabled="!charge.charge"
                      required
                      :rules="[
                        (val) =>
                          $globals.twoDecimalPlacesRegExp.test(val) ||
                          'Rate must have 2 decimal places only',
                        (val) =>
                          val > 0 || 'Rate can not be less than or equal to 0',
                      ]"
                      hide-details="auto"
                      v-model="charge.rate"
                      :error-messages="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i] &&
                        formErrors.customer_contract_charges[i].rate
                          ? formErrors.customer_contract_charges[i].rate
                          : null
                      "
                      @input="
                        formErrors.customer_contract_charges &&
                        formErrors.customer_contract_charges[i]
                          ? delete formErrors.customer_contract_charges[i].rate
                          : null
                      "
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    lg="auto"
                    class="Form-Columns-Dense align-self-center"
                  >
                    <v-btn
                      color="secondary"
                      small
                      depressed
                      @click="removeCharge(i)"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import Autocomplete from "@/components/FormBaseComponents/Autocomplete.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FormComponent from "@/components/General/FormComponent.vue";
import DateField from "@/components/FormBaseComponents/DateField.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    DateField,
    InputField,
    MultiSelect,
    FormComponent,
    Autocomplete,
  },

  data() {
    return {
      contractDetails: {
        customer_contract_rates: [{}],
        customer_contract_charges: [],
      },
      formErrors: {
        customer_contract_rates: [{}],
        customer_contract_charges: [{}],
      },

      allCustomerList: [],
      allContainersType: [],
      // allLocations: [],
      allJobType: [],
      allLaneList: [],

      stepper: "1",
      allCharges: [],
      listLoading: false,
      laneCount: 0,
      customerCount: 0,

      today: new Date().toISOString().slice(0, 10),
      isValid: true,
      isValid2: true,

      today: new Date().toISOString().slice(0, 10),
      keyUpdate: 0,
      backRoute: {
        path: "/app/admin/customer-contract/list",
      },
    };
  },

  methods: {
    addCharge() {
      this.contractDetails.customer_contract_charges.push({});
      this.formErrors.customer_contract_charges.push({});
    },

    removeCharge(i) {
      this.contractDetails.customer_contract_charges.splice(i, 1);
      this.formErrors.customer_contract_charges.splice(i, 1);
    },
    getCharges() {
      this.$bus.$emit("showLoader", true);
      this.$api.charge
        .getChargeSelectList({
          limit: "all",
          is_active: "true",
          is_system_generated: "false",
        })
        .then((res) => {
          this.allCharges = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addContainerType() {
      this.contractDetails.customer_contract_rates.push({});
      this.formErrors.customer_contract_rates.push({});
    },

    removeContainerType(i) {
      this.contractDetails.customer_contract_rates.splice(i, 1);
      this.formErrors.customer_contract_rates.splice(i, 1);
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let data = {
        ...this.contractDetails,
      };

      if (data.lane && typeof data.lane == "object") {
        data.lane = data.lane.id;
      }
      if (data.customer && typeof data.customer == "object") {
        data.customer = data.customer.id;
      }

      let payload = {
        id: this.contractDetails.id,
        data: data,
      };
      this.$api.customerContract
        .updateCustomerContract(payload)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Customer Tariff updated Successfully!",
            color: "success",
          });
          this.$router.push(this.backRoute);
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't update Customer Tariff!",
            color: "error",
          });
          this.formErrors = { ...this.formErrors, ...err.data };
        });
    },
    getCustomerList(params = {}) {
      this.listLoading = true;
      params = {
        company_type: "Customer",
        offset: 0,
        limit: 10,
        ...params,
      };
      this.$api.company
        .getCompanySelectList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.customerCount = res.count;
          }

          if (params.offset == 0) {
            this.allCustomerList = res.data;
          } else {
            this.allCustomerList.push(...res.data);
          }
        })
        .catch(() => {
          this.listLoading = false;
        });
    },

    getContainersType() {
      this.$bus.$emit("showLoader", true);
      this.$api.containerType
        .getContainerTypeSelectList({ limit: "all" })
        .then((res) => {
          this.allContainersType = res.data;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
     

    getCustomerContractOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractOptions()
        .then((res) => {
          this.allJobType = res.data.actions.POST.job_type.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    setChargeDetails(val, index) {
      if (val) {
        this.contractDetails.customer_contract_charges[index].charge = val.id;
        this.contractDetails.customer_contract_charges[index].charge_title =
          val.charge_title;
        this.contractDetails.customer_contract_charges[index].gl_no = val.gl_no;
        this.contractDetails.customer_contract_charges[index].description =
          val.description;
        this.contractDetails.customer_contract_charges[index].rate = val.rate;
        this.contractDetails.customer_contract_charges[index].tax_percentage =
          val.tax_percentage;

        this.keyUpdate++;
      } else {
        this.contractDetails.customer_contract_charges[index] = {};
      }
    },
    getCustomerContractObject(id) {
      this.$bus.$emit("showLoader", true);
      this.$api.customerContract
        .getCustomerContractObject(id)
        .then((res) => {
          for (let i = 1; i < res.data.customer_contract_rates.length; i++) {
            this.formErrors.customer_contract_rates.push({});
          }
          this.contractDetails = res.data;
          const lane = this.contractDetails?.lane;
          const customer = this.contractDetails?.customer;
          if (lane) {
            this.getLaneObject(lane);
          } else {
            this.$bus.$emit("showLoader", false);
          }
          if (customer) {
            this.getCustomerObject(customer);
          } else {
            this.$bus.$emit("showLoader", false);
          }
        })
        .catch((err) => {
          this.$router.push("/app/admin/customer-contract/list");
          this.$bus.$emit("showToastMessage", {
            message: `Couldn't find Customer Tariff with given id! ${
              this.$route && this.$route.params && this.$route.params.id
                ? this.$route.params.id
                : ""
            }`,
            color: "error",
          });
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    getLaneList(params = {}) {
      this.listLoading = true;
      params = {
        is_active: true,
        offset: 0,
        limit: 10,
        ...params,
      };

      this.$api.lane
        .getLaneList(params)
        .then((res) => {
          this.listLoading = false;
          if ("count" in res) {
            this.laneCount = res.count;
          }

          if (params.offset == 0) {
            this.allLaneList = res.data;
          } else {
            this.allLaneList.push(...res.data);
          }
        })
        .catch((err) => {
          this.listLoading = false;
        });
    },

    getLaneObject(id) {
      this.$api.lane
        .getLaneObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.contractDetails.lane = res.data;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    getCustomerObject(id) {
      this.$api.company
        .getCompanyObject(id)
        .then((res) => {
          this.$bus.$emit("showLoader", false);
          this.contractDetails.customer = res.data;
        })
        .catch((err) => {
          this.$bus.$emit("showLoader", false);
        });
    },

    setBackRoute() {
      if ("backRoute" in this.$route.query) {
        this.backRoute.path = this.$route.query.backRoute;
      }
      if ("active_tab" in this.$route.query) {
        if (!this.backRoute.query) {
          this.backRoute.query = {};
        }
        this.backRoute.query.active_tab = this.$route.query.active_tab;
      }
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.change) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.getCustomerList();
        this.getContainersType();
        this.getCharges();
        this.getLaneList();
        // this.getLocationList();
        this.setBackRoute();
        this.getCustomerContractOptions();
        this.getCustomerContractObject(this.$route.params.id);
      }
    }
  },
};
</script>
