<template>
  <ListComponent title="Transporters" :totalCount="totalItems">
    <template #list>
      <v-row class="ma-0">
        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex align-center">
          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchTransporters"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>
            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>

          <Columns
            :headers="headers"
            :gridColInstance="gridColumnApi"
            localStorageKey="transporterCompanyColumns"
          />
        </v-col>

        <v-col
          v-if="totalItems > 0"
          cols="8"
          class="px-0 pt-0 pb-2 d-flex justify-end"
        >
          <Pagination
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="transporterCompayList"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            @columnMoved="resizeGrid"
            @columnVisible="resizeGrid"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="allTransporterList"
          />
        </v-col>
      </v-row>

      <DetailsParent v-model="detailsDrawer" :resizable="true">
        <template #details>
          <TransporterDetails
            :transporterId="selectedTransporterId"
            :permissions="permissions"
            :userPermissions="userPermissions"
          />
        </template>
      </DetailsParent>
    </template>

    <template #dialogs>
      <AdminTransporterFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        @resetFilters="resetFilters"
        @applyFilters="itemsPerPageChanged(20)"
      />
      <BulkUpload
        v-model="comapnyBulkUploadDialog"
        :uploadTo="uploadType"
        @refreshList="itemsPerPageChanged(10)"
      />
    </template>

    <template #actions>
      <v-btn
        v-if="permissions && permissions.add"
        @click="addTransporter"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Add Transporter</span>
      </v-btn>
      <v-btn
        class="ml-3"
        v-if="permissions && permissions.add"
        @click="openCompanyBulkUpload('transporterCompany')"
        color="primary"
        depressed
      >
        <v-icon>mdi-plus</v-icon>
        <span>Bulk Upload</span>
      </v-btn>
    </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import TransporterDetails from "@/components/ModuleBased/details/TransporterDetails.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";
import TransporterButton from "@/components/AgGridComponents/TransporterButton.vue";
import DetailsParent from "@/components/General/DetailsParent.vue";
import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminTransporterFilters from "@/components/ModuleBased/dialogs/filters/AdminTransporterFilters.vue";
import BulkUpload from "@/components/General/BulkUpload.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    Columns,
    TransporterDetails,
    AgGridVue,
    Pagination,
    TransporterButton,
    DetailsParent,
    agColumnHeader: CustomHeader,
    AdminTransporterFilters,
    BulkUpload,
  },
  data() {
    return {
      sorting: {},
      headers: [
        {
          headerName: "Company Code",
          field: "company_code",
          sortable: true,
          minWidth: 115,
        },
        {
          headerName: "Company Name",
          field: "company_name",
          sortable: true,
          tooltipField: "company_name",
          minWidth: 400,
        },
        {
          headerName: "Address",
          field: "address_line_1",
          sortable: true,
          tooltipField: "address_line_1",
          minWidth: 400,
        },
        {
          headerName: "City",
          field: "city_name",
          sortable: true,
          tooltipField: "city_name",
          minWidth: 90,
        },
        {
          headerName: "Country",
          field: "country_code",
          sortable: true,
          minWidth: 80,
        },
        {
          headerName: "Contact No",
          sortable: true,
          field: "full_company_contact_number",
          tooltipField: "full_company_contact_number",
          minWidth: 150,
        },
        {
          headerName: "Email Id",
          sortable: true,
          field: "company_email",
          tooltipField: "company_email",
          minWidth: 150,
        },
        {
          headerName: "Payment Term",
          field: "payment_term",
          sortable: true,
          minWidth: 115,
        },
        {
          headerName: "Actions",
          field: "actions",
          cellRendererFramework: "TransporterButton",
          pinned: "right",
          minWidth: 120,
        }
      ],
      allTransporterList: [],

      // pagination vars
      itemsPerPage: 20,
      pageNo: 1,
      totalItems: 0,
      filters: {},

      // details vars
      selectedTransporterId: null,
      permissions: null,

      // bulk vars
      showBulkDialog: false,

      detailsDrawer: false,

      // Filter Vars
      filters: {},
      showFilterDialog: false,

      // bulk vars
      showBulkDialog: false,
      uploadType: "transporterCompany",
      comapnyBulkUploadDialog: false,

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        resizable: true,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
        rowHeight: 35,
      },
    };
  },

  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },
  methods: {
    openCompanyBulkUpload(type) {
      this.uploadType = type;
      this.comapnyBulkUploadDialog = true;
    },
    resizeGrid() {
      if (this.gridOptions && this.gridOptions.api) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(20);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchTransporters() {
      this.pageNo = 1;
      this.getTransporterList();
    },
    getTransporterDetails(company) {
      this.selectedTransporterId = company.id;
      this.detailsDrawer = true;
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getTransporterList();
    },
    prevPage() {
      this.pageNo--;
      this.getTransporterList();
    },
    nextPage() {
      this.pageNo++;
      this.getTransporterList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.getTransporterList();
    },
    getTransporterList(params = {}) {
      this.$bus.$emit("showLoader", true);
      params = {
        company_type: "Transporter",
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
      };
      if ("ordering" in params && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }
      this.$api.company
        .getCompanyList(params)
        .then((res) => {
          this.allTransporterList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    addTransporter() {
      this.$router.push("/app/admin/company-management/transporter/add");
    },
    editTransporter(id) {
      this.$router.push(`/app/admin/company-management/transporter/edit/${id}`);
    },
    deleteTransporter() {
      this.$bus.$emit("showToastMessage", {
        message: "This is a test",
      });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("transporter");
      const userPermissions = getPermissions("user");

      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/app/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.userPermissions = { ...userPermissions };
        this.getTransporterList();
      }
    }
  },
};
</script>
