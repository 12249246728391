<template>
  <FormComponent
    :title="`Add New Ticket`"
    :backRoute="backRoute"
    :nonFieldErrors="formErrors.non_field_errors"
  >
    <!-- Action Button -->
    <template #formActions>
      <v-btn color="primary" depressed :disabled="!isValid" @click="submitForm">
        Submit
      </v-btn>

      <v-btn color="secondary" class="ml-2" depressed @click="resetForm">
        Reset
      </v-btn>
    </template>

    <template #formContent>
      <v-form v-model="isValid" ref="customerTicketForm">
        <v-row class="ma-0">
          <v-col cols="12" sm="6" md="6" lg="6" class="pa-2">
            <InputField
              hide-details="auto"
              type="text"
              :label="`Title*`"
              :rules="[(val) => !!val || 'Title is required!']"
              v-model="ticketDetails.title"
              :error-messages="formErrors.title"
              @input="delete formErrors.title"
            />
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :label="`Module*`"
              :items="ticketModuleList"
              itemText="display_name"
              itemValue="value"
              :rules="[(val) => !!val || 'Module is required!']"
              v-model="ticketDetails.module"
              :error-messages="formErrors.module"
              @change="delete formErrors.module"
            ></MultiSelect>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" class="pa-2">
            <MultiSelect
              hide-details="auto"
              :label="`Priority*`"
              :items="ticketPriorityList"
              itemText="display_name"
              itemValue="value"
              :rules="[(val) => !!val || 'Priority is required!']"
              v-model="ticketDetails.priority"
              :error-messages="formErrors.priority"
              @change="delete formErrors.priority"
            ></MultiSelect>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="6" class="pa-2">
        
            <TextareaField
              hide-details="auto"
              v-model="ticketDetails.description"
              counter
              variant="outlined"
              rows="3"
              :label="`Description`"
              required
              :error-messages="formErrors.description"
              @input="delete formErrors.description"
            >
            </TextareaField>
          </v-col>
          <v-col cols="12" sm="6" md="6" lg="5" class="pa-2">
            <v-file-input
              hide-details="auto"
              v-model="attachment"
              :label="`Upload attachment`"
              accept="image/x-png,image/jpg,image/jpeg,application/pdf"
              :rules="[
                (file) =>
                  !file ||
                  file.size < 2e6 ||
                  'File size should be less than 2 MB!',
              ]"
              outlined
              dense
              :error-messages="formErrors.attachment"
              @change="delete formErrors.attachment, checkFileFormat($event)"
            />
          </v-col>

          <v-col cols="12" sm="6" md="6" lg="1" class="pa-2">
            <v-btn
              color="primary"
              depressed
              :disabled="isDisabled"
              @click="addDocument"
            >
              Add
            </v-btn>
          </v-col>

          <v-col
            v-show="ticketNewDocuments && ticketNewDocuments.length"
            cols="12"
            class="px-2 pt-2 pb-0"
          >
            <span class="text-h6 font-weight-regular"> New Attachment </span>
          </v-col>
          <v-col
            v-if="ticketNewDocuments && ticketNewDocuments.length"
            cols="12"
            class="pa-2"
          >
            <v-card elevation="0" outlined>
              <v-card-text class="py-1 px-0">
                <v-row
                  class="ma-0"
                  v-for="(data, i) in ticketNewDocuments"
                  :key="i"
                >
                  <v-col cols="12" lg="9" class="py-2">
                    <a :href="generateUrl(data.attachment)" target="_black">
                      <span>{{ data.attachment.name }}</span>
                    </a>
                  </v-col>

                  <v-col cols="6" lg="2" class="py-2">
                    <span>{{ getSize(data.attachment.size) }}</span>
                  </v-col>

                  <v-col cols="6" lg="1" class="text-right py-2">
                    <v-icon small color="primary" @click="removeFile(i)">
                      mdi-close
                    </v-icon>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </template>
  </FormComponent>
</template>

<script>
import InputField from "@/components/FormBaseComponents/InputField.vue";
import MultiSelect from "@/components/FormBaseComponents/MultiSelect.vue";
import FileField from "@/components/FormBaseComponents/FileField.vue";
import TextareaField from "@/components/FormBaseComponents/TextareaField.vue";
import FormComponent from "@/components/General/FormComponent.vue";

import { getPermissions } from "@/utils/functions";

export default {
  components: {
    InputField,
    MultiSelect,
    FileField,
    TextareaField,
    FormComponent,
    InputField,
    InputField,
  },

  data() {
    return {
      backRoute: { path: "/app/admin/ticket/list" },

      isValid: true,
      formErrors: {},
      ticketDetails: {},
      ticketPriorityList: [],
      ticketModuleList: [],

      // fields for docs
      ticketNewDocuments: [],
      attachment: null,
    };
  },

  computed: {
    currentProfile() {
      if (JSON.parse(localStorage.getItem("userProfile"))) {
        return JSON.parse(localStorage.getItem("userProfile"));
      } else {
        return null;
      }
    },
    isDisabled() {
      if (this.attachment) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {},
  methods: {
    addDocument() {
      this.ticketNewDocuments.push({
        attachment: this.attachment,
      });
      this.attachment = null;
    },
    removeFile(index) {
      this.ticketNewDocuments.splice(index, 1);
    },
    generateUrl(file) {
      return URL.createObjectURL(file);
    },
    getSize(bytes) {
      var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      if (bytes == 0) return "0 Byte";
      var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
      return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
    },
    checkFileFormat(event) {
      if (event) {
        const name = event.name;
        const lastDot = name.lastIndexOf(".");
        const ext = name.substring(lastDot + 1);
        let allowed = /(\jpg|\jpeg|\pdf|\png)$/i;
        if (!allowed.exec(ext)) {
          this.$nextTick(() => {
            this.attachment = null;
            this.$bus.$emit("showToastMessage", {
              message: "This file format is not supported!",
              color: "error",
            });
          });
          return false;
        } else {
          return true;
        }
      }
    },
    uploadTicketDocuments(id = null) {
      return new Promise((resolve, reject) => {
        this.ticketNewDocuments.forEach((details, index) => {
          let payload = {
            id: null,
            data: null,
          };
          payload.id = id;
          let newForm = new FormData();
          newForm.append("attachment", details.attachment);

          payload.data = newForm;
          this.$api.ticket
            .uploadTicketDocument(payload)
            .then((response) => {
              resolve(response);
            })
            .catch((err) => {
              reject(err);
            });
        });
      });
    },

    submitForm() {
      this.$bus.$emit("showLoader", true);
      let payload = {
        ticket_date: new Date().toISOString().slice(0, 10),
        company: this.currentProfile.company,
        ...this.ticketDetails,
      };
      this.$api.ticket
        .addTicket(payload)
        .then(async (res) => {
          if (this.ticketNewDocuments.length > 0) {
            await this.uploadTicketDocuments(res.data.response_data.id);
          }
          this.$bus.$emit("showLoader", false);
          this.$bus.$emit("showToastMessage", {
            message: "Ticket created successfully!",
            color: "success",
          });
          this.$router.push(this.backRoute);
        })
        .catch((err) => {
          this.formErrors = err.data;
          this.$bus.$emit("showLoader", false);
          console.error(err);
          this.$bus.$emit("showToastMessage", {
            message: "Couldn't create ticket!",
            color: "error",
          });
        });
    },
    getTicketOptions() {
      this.$bus.$emit("showLoader", true);
      this.$api.ticket
        .getTicketOptions()
        .then((res) => {
          this.ticketModuleList = res.data.actions.POST.module.choices;
          this.ticketPriorityList = res.data.actions.POST.priority.choices;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          console.error(err);
          this.$bus.$emit("showLoader", false);
        });
    },
    resetForm() {
      if (this.$refs.customerTicketForm) {
        this.$refs.customerTicketForm.reset();
      }
    },
  },

  mounted() {},

  beforeMount() {
    this.getTicketOptions();
  },
};
</script>
