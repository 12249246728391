
 <template>
  <ListComponent
    
    :title="`LRFQ Request Details for Lrfq - ${alllrfqList[0].lrfq_id}`"
    :totalCount="totallrfqStatistics.total_lrfq"
  >
    <template #list>
      <v-row class="ma-0">
        <v-col ccols="4" class="pl-0 pr-2 pt-0 pb-2 d-flex align-center">
          <!-- Tabs -->

          <v-text-field
            label="Search"
            outlined
            dense
            append-icon="mdi-magnify"
            clearable
            hide-details="auto"
            v-model="filters.search"
            @input="searchLrfq"
          ></v-text-field>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                depressed
                icon
                v-on="on"
                color="primary"
                v-bind="attrs"
                @click="showFilterDialog = true"
                class="mx-2"
              >
                <v-badge
                  dot
                  color="primary"
                  :value="Object.keys(filters).length"
                >
                  <v-icon>mdi-filter</v-icon>
                </v-badge>
              </v-btn>
            </template>

            <span v-if="Object.keys(filters).length == 0">Filters</span>

            <v-row v-else no-gutters>
              <v-col cols="12" class="font-weight-bold">Applied Filters</v-col>
              <v-col cols="12">
                <span
                  v-for="(value, key) in filters"
                  :key="key"
                  class="text-capitalize"
                  >{{ key.replace("_", " ") }}
                  <br />
                </span>
              </v-col>
            </v-row>
          </v-tooltip>
          <Columns :headers="headers" :gridColInstance="gridColumnApi" />
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2">
          <v-row>
            
          </v-row>
        </v-col>

        <v-col cols="4" class="px-0 pt-0 pb-2 d-flex justify-end">
          <Pagination
            v-if="totalItems > 0"
            :pageNo="pageNo"
            :totalItems="totalItems"
            :pageSize="itemsPerPage"
            @itemsPerPageChange="itemsPerPageChanged"
            @prevPage="prevPage"
            @nextPage="nextPage"
          />
        </v-col>

        <v-col cols="12" class="pa-0">
          <AgGridVue
            style="width: 100%; height: calc(100vh - 172px)"
            class="ag-theme-alpine"
            id="myGrid"
            :columnDefs="headers"
            :context="context"
            @grid-ready="onGridReady"
            :defaultColDef="defaultColDef"
            :grid-options="gridOptions"
            :suppressDragLeaveHidesColumns="true"
            :rowData="alllrfqList"
          ></AgGridVue>
        </v-col>
      </v-row>
    </template>

    <template #dialogs>
      <AdminLrfqRequestFilters
        v-model="showFilterDialog"
        :bindingObject="filters"
        :currentTab="tab"
        @resetFilters="resetFilters()"
        @applyFilters="itemsPerPageChanged(15)"
      />
    </template>

    <template #actions> </template>
  </ListComponent>
</template>

<script>
import Columns from "@/components/General/Columns.vue";
import ListComponent from "@/components/General/ListComponent.vue";
import { AgGridVue } from "ag-grid-vue";
import Pagination from "@/components/General/Pagination.vue";

import CustomHeader from "@/components/AgGridComponents/General/CustomHeader.vue";
import AdminLrfqRequestFilters from "@/components/ModuleBased/dialogs/filters/AdminLrfqRequestFilters.vue";

import {
  isAuthenticated,
  hasRouteAccess,
  getPermissions,
} from "@/utils/functions";

export default {
  components: {
    ListComponent,
    AgGridVue,
    AdminLrfqRequestFilters,
    Pagination,

    agColumnHeader: CustomHeader,

    Columns,
  },
  data() {
    return {
      alllrfqList: [],

      lrfqLogs: [],
      totallrfqStatistics: [],
      statuses: ["Active", "Closed"],
      openFilterDialog: false,
      showLrfqLogDialog: false,
      showDialog: false,

      tab: 0,

      sorting: {},

      headers: [
        {
          headerName: "LRFQ Request ID",
          field: "lrfq_request_id",
          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Status",
          field: "status",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Transporter",
          field: "transporter_name",

          sortable: true,

          minWidth: 120,
        },
        {
          headerName: "Lane",
          field: "lane_title",

          sortable: true,
          minWidth: 120,
        },
        {
          headerName: "Container Type",
          field: "container_type_name",

          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Container Count",
          field: "container_count",

          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Job Type",
          field: "job_type",
          sortable: true,
          minWidth: 140,
        },
        {
          headerName: "Rate",
          field: "rate",
          sortable: true,
          minWidth: 140,
        },

        {
          headerName: "Rejection Remarks",
          field: "rejection_remarks",
          minWidth: 150,
          sortable: true,
          tooltipField: "rejection_remarks",
        },
        {
          headerName: "Transporter Remarks",
          field: "transporter_remarks",
          minWidth: 150,
          sortable: true,
          tooltipField: "transporter_remarks",
        },
      ],
      groupDisplayType: "groupRows",

      // details vars

      permissions: null,

      // pagination vars
      pageNo: 1,
      itemsPerPage: 15,
      totalItems: 0,

      // filter vars
      filters: {},
      showFilterDialog: false,

      headerSelected: [],

      // ag-grid vars
      gridApi: null,
      gridColumnApi: null,
      defaultColDef: {
        width: 150,
        resizable: true,
        flex: 1,
      },
      gridOptions: {
        onGridSizeChanged: () => {
          this.resizeGrid();
        },
        suppressRowClickSelection: true,
        suppressDragLeaveHidesColumns: true,
        enableCellTextSelection: true,
        tooltipShowDelay: 0,
      },
    };
  },
  watch: {
    tab() {
      this.getLrfqList();
    },
  },
  computed: {
    context() {
      return { parentComponent: this };
    },
    offset() {
      return this.itemsPerPage * (this.pageNo - 1);
    },
  },

  methods: {
    resizeGrid() {
      if (this.gridOptions && "api" in this.gridOptions) {
        setTimeout(() => {
          this.gridOptions.api.sizeColumnsToFit();
        }, 100);
      }
    },
    resetFilters() {
      this.filters = {};
      this.itemsPerPageChanged(15);
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.resizeGrid();
    },
    searchLrfq() {
      this.pageNo = 1;
      this.getLrfqList();
    },

    headersChanged(value) {
      this.headerSelected = value;
      this.resizeGrid();
    },
    itemsPerPageChanged(e) {
      this.pageNo = 1;
      this.itemsPerPage = e;
      this.getLrfqList();
    },
    prevPage() {
      this.pageNo--;
      this.getLrfqList();
    },
    nextPage() {
      this.pageNo++;
      this.getLrfqList();
    },
    applyGridSort(key, type) {
      if (this.filters && !this.filters.ordering) {
        this.filters.ordering = [];
      }

      if (type == null) {
        delete this.sorting[key];
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(`-${key}`),
          1
        );
      } else if (type == "asc") {
        this.filters.ordering.push(key);
      } else if (type == "desc") {
        this.filters.ordering.splice(
          this.filters.ordering.indexOf(key),
          1,
          `-${key}`
        );
      }
      if (this.filters.ordering.length == 0) {
        delete this.filters.ordering;
      }
      this.getLrfqList();
    },
    getLrfqList(params = {}) {
      this.$bus.$emit("showLoader", true);
      let laneId;

      if (this.filters && this.filters.lane) {
        laneId = this.filters.lane.id;
        delete this.filters.lane;
        this.filters.lane = laneId;
      }

      params = {
        offset: this.offset,
        limit: this.itemsPerPage,
        ...params,
        ...this.filters,
        lrfq: this.$route.params.id,
      };

      if (params.ordering && params.ordering.length > 0) {
        params = { ...params, ordering: params.ordering.join(",") };
      }

      this.$api.lrfq
        .getLrfqRequestList(params)
        .then((res) => {
          this.alllrfqList = res.data;
          this.totalItems = res.count;
          this.$bus.$emit("showLoader", false);
        })
        .catch((err) => {
          if (err && err.data) {
            const showToast = (message) => {
              this.$bus.$emit("showToastMessage", {
                message,
                color: "error",
                hasActions: true,
              });
            };

            if (err.data.message) {
              showToast(err.data.message);
            } else if (
              err.data.error &&
              err.data.error.non_field_errors &&
              err.data.error.non_field_errors.length
            ) {
              showToast(err.data.error.non_field_errors.join(", "));
            } else {
              showToast("Something Went Wrong");
            }
          }
          this.$bus.$emit("showLoader", false);
        });
    },
  },
  beforeMount() {
    if (!isAuthenticated()) {
      this.$router.push("/");
    } else if (!hasRouteAccess(this.$route)) {
      this.$router.push(`/${localStorage.getItem("user_type").toLowerCase()}`);
    } else {
      const permissions = getPermissions("contract");
      if (!permissions || !permissions.view) {
        this.$bus.$emit("showToastMessage", {
          message: "You don't have access to this page",
          color: "error",
        });
        this.$router.push(
          `/${
            localStorage.getItem("user_type")
              ? localStorage.getItem("user_type").toLowerCase()
              : "error"
          }`
        );
      } else {
        this.permissions = { ...permissions };
        this.getLrfqList();
      }
    }
  },
};
</script>